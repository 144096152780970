import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import * as AcfLayout from '../components/Acf';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/helpers';
import { EnquiryHeader } from '../components/Acf/EnquiryHeader'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { BlogHeader } from '../components/Acf/Blog/Header'
import './post.scss';
import RenderContent from '../components/RenderContent';

const AcfComponent = ({ location, componentName, item, pageContext }) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = AcfLayout[componentName];
  const componentTitle = item.__typename ? item.__typename.replace('WordPressAcf_', '') : 'No name';
  if (!ComponentName) {
    return (
      <section className="page missing"><div className="inner">The ACF component <strong>"{componentTitle}"</strong> is missing. <span>&#128540;</span></div></section>
    )
  } else {
    return (
      <ComponentName
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }
};

const BlogPost = (props) => {
  const { data, location, pageContext } = props;

  const { wordpressPost: post, siteSettings, wordpressWpSettings } = data;
  const { blogBottomForm } = siteSettings.options;
  if (!post) return null;
  const { title, yoast, acf = {}, content, featured_image_url, date } = post || {};
  const { layout, blogLayout = [] } = acf;
  return (
    <Layout isBlog location={location}>
      <SEO
        title={`${decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={yoast.metaDescription}
        yoast={yoast}
      />
      <Breadcrumbs location={location} />
      <div className="meta"><span className="date">{date}</span></div>
      {content && (
        <div className="blog-post-content-wrapper">
          <BlogHeader title={title} subTitle={''} image={featured_image_url && featured_image_url} />

          <RenderContent content={content} />
        </div>
      )}
      {layout && layout?.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_', '');
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            location={location}
            pageContext={pageContext}
            date={date}
          />
        )
      })}
      {typeof blogLayout !== "undefined" && blogLayout && blogLayout?.map((item, index) => {
        if (!item) return null;
        const layoutComponentName = item?.acf_fc_layout
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            location={location}
            pageContext={pageContext}
          />
        )
      })}
      {blogBottomForm && <EnquiryHeader {...blogBottomForm} />}
    </Layout>
  )
}

export default Previewable(BlogPost, 'post');

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
        blogBottomForm {
          actionText
          content
          formId
          subTitle
          title
        }
      }
    },
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_image_url {
        source_url
      }
      categories {
        name
        slug
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        layout: blogLayout_post {
          __typename
          ... BlogCTAQuery
          ... BlogHeaderQuery
          ... BlogContentBlockQuery
        }
      }
    }
  }
`
